import Divider from '@material-ui/core/Divider';
import MaterialDrawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SpeedRoundedIcon from '@material-ui/icons/SpeedRounded';
import SearchIcon from '@material-ui/icons/Search';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import classNames from 'classnames';
import React from 'react';
import {
  Feature,
  getConfig,
  getCurrentEnv,
  isFeatureEnabled,
} from '../../config';
import styles from './Drawer.styled';
import { ProductboardIcon } from '../../features/productboard/Productboard';
import { useAppAuth } from '../FirebaseAuth';

interface IDrawerProps {
  classes: any;
  open: boolean;
  onClose: () => void;
  onNavigateToUrl: (url: string) => void;
}

const config = getConfig();
const Drawer: React.FC<IDrawerProps> = (props: IDrawerProps) => {
  const { getJustToken } = useAppAuth();
  const isNotProd = getCurrentEnv() !== 'production';

  async function onVersionClick() {
    if (isNotProd) {
      const token = await getJustToken();
      if (token) {
        await navigator.clipboard.writeText(token);
        alert('Token saved to clipboard, have fun');
      }
    }
  }

  const { classes, open, onClose, onNavigateToUrl } = props;
  return (
    <MaterialDrawer
      variant="permanent"
      classes={{
        paper: classNames(
          classes.drawerPaper,
          !open && classes.drawerPaperClose,
        ),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        <div>
          <ListItem button onClick={() => onNavigateToUrl('/users')}>
            <ListItemIcon>
              <SearchIcon />
            </ListItemIcon>
            <ListItemText primary="Search" />
          </ListItem>
          {isFeatureEnabled(Feature.Referrals) ? (
            <ListItem button onClick={() => onNavigateToUrl('/referrals')}>
              <ListItemIcon>
                <AccountTreeRoundedIcon />
              </ListItemIcon>
              <ListItemText primary="Referrals" />
            </ListItem>
          ) : null}
          <ListItem button onClick={() => onNavigateToUrl('/tallyups')}>
            <ListItemIcon>
              <SpeedRoundedIcon />
            </ListItemIcon>
            <ListItemText primary="Odometer Tally Ups" />
          </ListItem>
          <ListItem button onClick={() => onNavigateToUrl('/productboard')}>
            <ListItemIcon>
              <ProductboardIcon />
            </ListItemIcon>
            <ListItemText primary="Productboard" />
          </ListItem>
        </div>
      </List>
      <div
        onClick={onVersionClick}
        style={{ cursor: isNotProd ? 'pointer' : 'default' }}
        className={classes.version}
      >{`V. ${config.REACT_APP_VERSION}`}</div>
    </MaterialDrawer>
  );
};

export default withStyles(styles)(Drawer);
